import React, { useEffect, useState } from "react";
import { Button, Pagination, Table, Tooltip,Spin, Modal } from "antd";
import { IoIosAddCircleOutline } from "react-icons/io";
import Title from "../../component/PageTitle";
import { useNavigate } from "react-router-dom";
import { useGetSubAccountQuery } from "../../slices/getSlice";
import { FaEdit } from "react-icons/fa";
import { LuEye } from "react-icons/lu";
import { MdBlockFlipped } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";
import { toast } from "react-toastify";
import { useDeactivateUserMutation } from "../../slices/apiSlice";
const AllSubAccount = () => {
  const pageSize = 5;
  const {
    data: getSubAccount,
    isLoading,
    isError,
    refetch,
    error
  } = useGetSubAccountQuery();
 const[deactivateUser,{}] = useDeactivateUserMutation()
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1); 
  const[selectedRecord,setSelectedRecord] = useState()
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dataSource = getSubAccount? getSubAccount.map((item) => {
    return{
        id:item.id,
        name:item?.first_name+ " " + item?.last_name,
        first_name:item.first_name,
        last_name:item.last_name,
        email:item?.email,
        owner_type:item?.ownership_type_name,
        ownership_type_id:item.ownership_type_name,
        properties:item.properties,
        property_id:item.properties.map((data) => {
          return data?.property_name;
        }),
    }
       
      })
    : [];
    useEffect(()=>{
refetch()

    },[])
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };
  const totalItems = dataSource ? dataSource.length : 0;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalItems);
  const displayedItems = dataSource.slice(startIndex, endIndex);
  const Columns = [
    {
      title: "S.No.",
      dataIndex: "serial",
      render: (_, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Owner Type",
      dataIndex: "owner_type",
    },
    {
        title: "Action",
        dataIndex: "action",
        render: (_, record) => {
          return (
            <div  className="action-container">
               <Tooltip title="View assign proeprty">
                            <button className="btn btnView me-2" onClick={()=>{ navigate('/assign-property', { state: record }) }} >
                                <LuEye  />
                            </button>
                        </Tooltip>
          <Tooltip title="Edit">
              <button
                className="btn btnEdit me-2"
                onClick={() => { navigate('/create_sub_account', { state: record }) }}
              >
                <FaEdit />
              </button>
            </Tooltip>
            <Tooltip title="Disable user">
                            <button className="btn btnBlock me-2" onClick={()=>{setIsModalOpen(true);setSelectedRecord(record.id)}} >
                            <MdBlockFlipped  />
                            </button>
                        </Tooltip>

            </div>
          
          );
        },
      },
  ];
  const  handleOk = async () => {
    const res = await deactivateUser(selectedRecord);
    if (res?.data?.message) {
        refetch();
        setIsModalOpen(false);
        toast.success(res?.data?.message)
    }
    else{
        toast.error("Not able to delete")
    }
};
const handleCancel = () => {
    setIsModalOpen(false);
  
};
  return (
    <div className="mainWrapper">
      {/* Button and Title Section */}
      <div className="card titleCard mb-4">
        <div className="card-body">
          <Title title="All Sub Account" />
          <Button
            type="primary"
            className="link"
            style={{
              backgroundColor: "#F47A3A",
            }}
            onClick={() => navigate("/create_sub_account")}
          >
            {"Create Sub Account"}{" "}
            <IoIosAddCircleOutline style={{ marginLeft: "10px" }} />
          </Button>
        </div>
      </div>

      <div className="card tableCard">
        <div className="card-body">
          {isLoading ? (
            <Spin size="large" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} />
          ) : isError || displayedItems.length< 0 ? (
            <h4>An error occurred</h4>
            ) : (
                
                <div className="table-responsives">
                  {displayedItems.length > 0 ? (
                    <>
                  <Table
                    rowKey={record => record.id}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={displayedItems}
                columns={Columns}
                pagination={false}
              />
              <Pagination
                className="mt-4"
                current={currentPage}
                pageSize={pageSize}
                total={totalItems}
                onChange={handleChangePage}
                      />
                    </>
                  ) : (
                      <h4>No data found</h4>
                  )}
              
            </div>
          )}
        </div>
      </div>
      <Modal
                className="deleteModal"
                title="Delete Property"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={false}
            >
                <div className="iconRow">
                    <RxCrossCircled/>
                </div>
                <div className="textRow">
                    <h3>Are you sure?</h3>
                    <p>Do you really want to remove this account?</p>
                </div>
                <div className="buttonRow">
                    <Button className="btnOutlined me-2" onClick={handleCancel}>Cancel</Button>
                    <Button className="btnFilled text-white" onClick={() => handleOk()}>Delete</Button>
                </div>
            </Modal>
    </div>
  );
};
export default AllSubAccount;
