import { Button, Form, Input, Modal } from "antd";
// import Footer from "../../Components/Footer";
import Title from "../../component/PageTitle";
import { LiaUserEditSolid } from "react-icons/lia";
import { PiHouseLine } from "react-icons/pi";
import DynamicForm from "../../component/Form";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import {
  useAddCategoryMutation,
  useAddIncomeMutation,
  useAddPropertyMutation,
  useEditIncomeMutation,
  useEditPropertyMutation,
} from "../../slices/apiSlice";
import { toast } from "react-toastify";
import {
  useGetCategoryQuery,
  useGetPropertiesQuery,
} from "../../slices/getSlice";
import { useEffect, useState } from "react";
import { SlCalender } from "react-icons/sl";
import { useLocation, useNavigate } from "react-router-dom";
import CommonModal from "../../component/CommonModal";

const AddIncome = () => {
  const [form] = Form.useForm();
  const [modal] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [modalopen, setModalOpen] = useState();
  const [property, setProperty] = useState();
  const [labelsModal, setLabelsModal] = useState(false);
  const [category, setCategory] = useState();
  const [FormInitialValues, setFormInitialValues] = useState(location?.state);
  const [FormInitialValuesDefault, setInitialValuesDefault] = useState({
    name: "",
  });
  const [isSaving , setIsSaving] = useState(false)
  const {
    data: getProperties,
    refetch: refetchProperties,
    error
  } = useGetPropertiesQuery();
  useEffect(() => {
    refetchProperties();
   
  }, [getProperties]);

  const {
    data: getCategory,
    refetch,
  } = useGetCategoryQuery('income');
  const [addIncome, { }] = useAddIncomeMutation();
  const [editIncome, { }] = useEditIncomeMutation();
  const [addCategory, { }] = useAddCategoryMutation();
  const field = [
    {
      component: "select",
      label: "Property",
      name: "property_id",
      placeholder: "Please select",
      suffixIcon: (
        <PiHouseLine style={{ color: "#F47939", fontSize: "25px" }} />
      ),
      rules: [
        {
          required: true,
          message: "Please select property ",
        },
      ],
      options: property,
      colSpan: 12,
    },
    {
      component: "select",
      label: "Type of income",
      name: "category_id",
      placeholder: "Please select",
      suffixIcon: (
        <PiHouseLine style={{ color: "#F47939", fontSize: "25px" }} />
      ),
      rules: [
        {
          required: true,
          message: "Please select type of income",
        },
      ],
      options: category,
      colSpan: 12,
    },
    {
      component: "input",
      type: "number",
      label: "Amount",
      name: "amount",
      placeholder: "Enter Amount",
      prefix: (
        <HiOutlineCurrencyDollar
          style={{ color: "#F47939", fontSize: "25px" }}
        />
      ),
      rules: [
        {
          required: true,
          message: "Please input your amount",
        },
        {
          pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
          message: 'Amount must be positive number',
        },
      ],
      colSpan: 12,
    },
    {
      component: "input",
      type: "date",
      label: "Start Date",
      name: "start_date",
      placeholder: "Enter start date ",
      prefix: (
        <SlCalender
          style={{ color: "#F47939", fontSize: "25px", height: "20px" }}
        />
      ),
      rules: [
        {
          required: true,
          message: "Please input your start date",
        },
      ],
      colSpan: 12,
    },
    {
      component: "input",
      type: "date",
      label: "End Date",
      name: "end_date",
      placeholder: "Enter start date ",
      prefix: (
        <SlCalender
          style={{ color: "#F47939", fontSize: "25px", height: "20px" }}
        />
      ),

      rules: [
        {
          required: true,
          message: "Please input your end date",
        },
      ],
      colSpan: 12,
    },
  ];
  const modalFields = [
    {
      component: "input",
      type: "text",
      label: `Enter Income Type`,
      name: "name",
      placeholder: `Enter income type`,
      prefix: (
        <LiaUserEditSolid style={{ color: "#F47939", fontSize: "25px" }} />
      ),
      rules: [
        {
          required: true,
          message: `Please input your income type`,
        },
        {
          pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
          message: '  income type  must only contain letters',
        },
      ],
    },
  ];
  useEffect(() => {
    if (getProperties && getProperties.data) {
      const transformedOptions = getProperties.data.map((item) => ({
        label: item.name_of_property,
        value: item.id,
      }));
      setProperty(transformedOptions);
    }
    if (getCategory && getCategory.data) {
      const transformedOptions = getCategory.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setCategory(transformedOptions);
    }
  }, [getProperties, getCategory]);

  const onFinish = async (values) => {
    setIsSaving(true)
    try {
      let id = location?.state?.id;
      const value = {
        // property_id: location?.state?.propertyId ?? values?.property_id,
        property_id: values?.property_id
          ? values?.property_id
          : location?.state?.propertyId,

        category_id: values?.category_id
          ? values?.category_id
          : location?.state?.categoryId,
        amount: values?.amount
          ? values?.amount
          : location?.state?.amount,
        start_date: values?.start_date
          ? values?.start_date
          : location?.state?.start_date,

        end_date: values?.end_date ? values?.end_date : location?.state?.end_date
      };
      if (value.start_date > value.end_date) {
        toast.error("Start date should be less than end date");
        return false;
      }

      if (id) {
        if (typeof value.category_id == "string") {
          let categoryData = category.find(
            (cat) => cat.label == value.category_id
          );
          value.category_id = categoryData.value;
        }
        if (typeof value.property_id == "string") {
          let proData = property.find((pro) => pro.label == value.property_id);
          value.property_id = proData.value;
        }

        const response = await editIncome({ data: value, id });

        if (response.data) {
          toast.success("Income has been updated successfully");
          form.resetFields();
          navigate("/income");
        } else {
          toast.error(`${response?.error?.data?.message}`);
        }
      } else {
        const response = await addIncome(values);
        if (response.data) {
          toast.success("Income has been created successfully");
          form.resetFields();
          navigate("/income");
        } else {
          toast.error(`${response?.data?.message}`);
        }
      }
    } catch {
      //  console.log(error)
    } finally {
      setIsSaving(false)
    }
   
  };
  const categoryAdd = async (values) => {
    setIsSaving(true)
    const value ={
      category_of:'income',
      name:values.name
    }
    try {
      const response = await addCategory(value);
      if (response.data) {
        toast.success("Income Type has been created successfully");
        modal.resetFields();
        refetch();
        setModalOpen(false);
      }else{
        toast.error(`${response?.error?.data?.message}`);
      }
    } catch (error) {
    //  console.log(error)
    } finally {
      setIsSaving(false)
    }
  };
  const openLabelModal = () => {
    setLabelsModal(true);
};

const handleLabelModal = () => {
    setLabelsModal(false);
};

  return (
    <div className="mainWrapper">
      <div className="card titleCard mb-4">
        <div className="card-body">
          {location?.state?.id ? (
            <Title title="Edit  Income" />
          ) : (
            <Title title="Add Income" />
          )}
        </div>
      </div>
      <div className="card tableCard">
        <div className="card-body ">
          <div className="d-flex justify-content-end align-items-center">
          {location?.state?.id?'': <Button
                            type="primary"
                            className="btnOutlined me-3"
                            onClick={() => {
                                openLabelModal()
                            }}
                        >
                            Change Labels
                        </Button>}
            <Button
              type="primary"
              className="btnFilled"
              onClick={() => {
                setModalOpen(true);
                setInitialValuesDefault({ name: "" });
              }}
            >
              Add Income Type
            </Button>
          </div>
          <DynamicForm
            form={form}
            fields={field}
            onFinish={onFinish}
            buttonName={location?.state?.id ? "Edit" : "Add"}
            FormInitialValues={FormInitialValues}
            ButtonDisable={isSaving}
          />
          <CommonModal
            open={modalopen}
            close={() => setModalOpen(false)}
            form={modal}
            onFinish={categoryAdd}
            FormInitialValues={FormInitialValuesDefault}
            fields={modalFields}
            title={"Add Income Type"}
            ButtonDisable={isSaving}
          />
        </div>
      </div>
      <Modal
                title="Change Form Labels"
                open={labelsModal}
                onOk={handleLabelModal}
                onCancel={handleLabelModal}
                className="assign_model"
                width={720}
                footer={false}
            >
                <div className="modalBody">
                    <Form
                        name="labelForm"
                        className="modalForm"
                        layout="vertical"
                    >
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item
                                    name="Property"
                                    label="Property"
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="Type of income"
                                    label="Type of income"
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="Amount"
                                    label="Amount"
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="Start Date"
                                    label="Start Date"
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="End Date"
                                    label="End Date"
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                           
                            <div className="col-md-12 text-center mt-4">
                                <Button type="primary" className="authBtn" >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
    </div>
  );
};

export default AddIncome;
