import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  useGetCategoryQuery,
  useGetProfitLossQuery,
  useGetTotalExpenseQuery,
  useGetTotalIncomeQuery,
  useGetUtilityCategoryQuery,
} from "../../slices/getSlice";
import TabContent from "./tabOneContent";
import { useSelector } from "react-redux";
import {
  useMonthlyExpenseMutation,
  useMonthlyIncomeMutation,
} from "../../slices/apiSlice";
import { Select, Space } from "antd";
import { useNavigate } from "react-router-dom";
const Report = () => {
  const navigate = useNavigate()
  const [providerType, setProviderType] = useState();
  const [incomeType, setIncomeType] = useState();
  const [filterIncome, setFilterIncome] = useState();
  const [filterExpanse, setFilterExpanse] = useState();
  const { data: getCategory, refetch } = useGetCategoryQuery('income');
  const { data: getTotalIncome, refetch: refetchIncome,error } =
    useGetTotalIncomeQuery();
  const { data: getTotalExpense, refetch: refetchExpense } =
    useGetTotalExpenseQuery();
  const { data: getProfitLoss, refetch: refetchProfitLoss } =
    useGetProfitLossQuery();
  const [getMonthlyIncome] = useMonthlyIncomeMutation();
  const [getMonthlyExpense] = useMonthlyExpenseMutation();
  const filteredProperties =
    getTotalIncome?.filter((property) => property.total_income !== null) || [];
  const propertyIncome = filteredProperties.map(
    (property) => property.property_name
  );
  const totalIncomes = filteredProperties.map(
    (property) => property.total_income
  );
  const finalres = totalIncomes.map((data) => parseFloat(data))
  const filteredExpanse =
    getTotalExpense?.filter((property) => property.total_amount !== null) || [];
  const propertyExpanse = filteredExpanse.map(
    (property) => property.property_name
  );
  const totalExpense = filteredExpanse.map(
    (property) => property.total_expense
  );
  const finalresofExpense = totalExpense.map((data) => parseFloat(data))
  const propertyNames = getProfitLoss?.map(
    (property) => property.property_name
  );
  const profits = getProfitLoss?.map((property) =>
    property.profit ? property.profit : 0
  );
  const losses = getProfitLoss?.map((property) =>
    property.loss ? property.loss : 0
  );
  const currencyGlobal = useSelector(
    (state) => state?.persistedReducer?.currency?.currency_symbol
  );
  const currency = currencyGlobal ? currencyGlobal : "";
  const incomeMonth = filterIncome?.map((item) => item.month);
  const income = filterIncome?.map((item) => item.total_amount);
  const expanseMonth = filterExpanse?.map((item) => item.month);
  const expanse = filterExpanse?.map((item) => item.total_amount);
  const { data: getUtilityCategory = [] } = useGetUtilityCategoryQuery();
  useEffect(() => {
    refetchIncome();
    refetchExpense();
    refetchProfitLoss();
    handleSelectIncome();
    handleSelectExpanse();
   
  }, [refetchIncome, refetchExpense, refetchProfitLoss]);
  useEffect(() => {
    if (getUtilityCategory && getUtilityCategory.data) {
      const transformedOptions = getUtilityCategory.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setProviderType(transformedOptions);
    }
    if (getCategory && getCategory.data) {
      const transformedOptions = getCategory.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setIncomeType(transformedOptions);
    }
  }, [getUtilityCategory, getCategory]);
  const incomeChart = {
    chart: {
      type: "column",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Total Income by Property",
    },
    plotOptions: {
      series: {
        pointWidth: 50,
      },
      column: {
        colorByPoint: true,
      },
    },

    colors: ["#3A54A5"],
    xAxis: {
      categories: propertyIncome,
      title: {
        text: "Property Name",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total Income",
      },
      labels: {
        formatter: function () {
          return currency + this.value;
        },
      },
    },

    series: [
      {
        name: "Total Incomes",
        data: finalres,
        color: "#0095FF",
      },
    ],
  };

  const expenseChart = {
    chart: {
      type: "column",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Total Expense by Property",
    },
    plotOptions: {
      series: {
        pointWidth: 50,
      },
      column: {
        colorByPoint: true,
      },
    },
    colors: ["#F47939"],
    xAxis: {
      categories: propertyExpanse,
      title: {
        text: "Property Name",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total Expense",
      },
      labels: {
        formatter: function () {
          return currency + this.value;
        },
      },
    },
    series: [
      {
        name: "Total Expense",
        data: finalresofExpense,
      },
    ],
  };

  const profitLossChart = {
    chart: {
      type: "line",
    },
    title: {
      text: "Profit and Loss by Property",
    },
    xAxis: {
      categories: propertyNames,
      title: {
        text: "Property Name",
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "Amount",
      },
      labels: {
        formatter: function () {
          return currency + this.value;
        },
      },
    },
    series: [
      {
        name: "Profit",
        data: profits,
        color: "green",
      },
      {
        name: "Loss",
        data: losses,
        color: "red",
      },
    ],
  };

  const monthIncomeChart = {
    chart: {
      type: "spline",
    },
    title: {
      text: "Monthly Income",
    },
    credits: {
      enabled: false,
    },

    plotOptions: {
      areaspline: {
        color: "#32CD32",
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "#32CD32"],
            [1, "#32CD3200"],
          ],
        },
        threshold: null,
        marker: {
          lineWidth: 1,
          lineColor: null,
          fillColor: "white",
        },
      },
    },
    xAxis: {
      categories: incomeMonth,
      title: {
        text: "Month Name",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total Expense",
      },
      labels: {
        formatter: function () {
          return currency + this.value;
        },
      },
    },
    series: [
      {
        name: "Total Incomes",
        data: income,
        color: "green",
      },
    ],
  };
  const monthExpanseChart = {
    chart: {
      type: "spline",
    },
    title: {
      text: "Monthly Expanse",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      areaspline: {
        color: "#e52027",
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "#32CD32"],
            [1, "#32CD3200"],
          ],
        },
        threshold: null,
        marker: {
          lineWidth: 1,
          lineColor: null,
          fillColor: "white",
        },
      },
    },
    xAxis: {
      categories: expanseMonth,
      title: {
        text: "Month Name",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total Expense",
      },
      labels: {
        formatter: function () {
          return currency + this.value;
        },
      },
    },
    series: [
      {
        name: "Total Expanse",
        data: expanse,
        color: "red",
      },
    ],
  };

  const handleSelectIncome = async (value) => {
    let data = {
      category_id: value,
    };
    const res = await getMonthlyIncome(data);
    setFilterIncome(
      res?.data?.filter((monthly) => monthly.total_amount !== null) || []
    );
  };
  const handleSelectExpanse = async (value) => {
    let data = {
      provider_type_id: value,
    };
    const res = await getMonthlyExpense(data);
    setFilterExpanse(
      res?.data?.filter((monthly) => monthly.total_amount !== null) || []
    );
  };

  const tabItems = [
    {
      key: "1",
      label: "Overall Performance",
      children: <TabContent />,
    },
  ];

  return (
    <div className="mainWrapper">
      <div className="card reportCard mb-4">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="cardTitle">Overall Performance</h3>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-6">
          <div className="card chartCard mb-4">
            <div className="card-body">
              <HighchartsReact highcharts={Highcharts} options={incomeChart} />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card chartCard mb-4">
            <div className="card-body">
              <HighchartsReact highcharts={Highcharts} options={expenseChart} />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card chartCard">
            <div className="card-body">
              <HighchartsReact
                highcharts={Highcharts}
                options={profitLossChart}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          

          <div className="card chartCard mb-4">
            <div className="card-body">
            <div className="highchart-select d-flex justify-content-end">
              <Select
            defaultValue={"Select Income Type"}
            style={{ width: 200 }}
            options={incomeType}
            optionRender={(option) => (
              <Space>
                <span role="img" aria-label={option.data.value}>
                  {option.data.label}
                </span>
              </Space>
            )}
            onChange={handleSelectIncome}
          /></div>
              <HighchartsReact
                highcharts={Highcharts}
                options={monthIncomeChart}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {/* <Select
            defaultValue={"Select Provider Type"}
            style={{ width: 200 }}
            options={providerType}
            optionRender={(option) => (
              <Space>
                <span role="img" aria-label={option.data.value}>
                  {option.data.label}
                </span>
              </Space>
            )}
            onChange={handleSelectExpanse}
          /> */}
          <div className="card chartCard mb-4">
            <div className="card-body">
              
              <div className="highchart-select d-flex justify-content-end">
              <Select
            defaultValue={"Select Provider Type"}
            style={{ width: 200 }}
            options={providerType}
            optionRender={(option) => (
              <Space>
                <span role="img" aria-label={option.data.value}>
                  {option.data.label}
                </span>
              </Space>
            )}
            onChange={handleSelectExpanse}
          />
              </div>
            
              <HighchartsReact
                highcharts={Highcharts}
                options={monthExpanseChart}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
