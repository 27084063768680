import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import { Spin } from 'antd';
import { useGetTopProfitablePropertiesMutation } from '../slices/apiSlice';

exporting(Highcharts);
exportData(Highcharts);


function formatNumber(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function ProfitChart(data) {
    if (!data) {
        return {};
    }

    const categories = data.map(property => property.name_of_property);
    const seriesData = data.map(property => {
        // Remove commas and convert to number
        const profit = property?.total_profit?.replace(/,/g, '');
        return parseFloat(profit);
    });
    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Top Profitable Properties',
            align: 'left'
        },
        xAxis: {
            categories: categories
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Total Profit'
            },
            stackLabels: {
                enabled: true,
                formatter: function() {
                    return formatNumber(this.total.toFixed(2));
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: 'Total Profit: {point.y:,.2f}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true,
                      formatter: function() {
                        return formatNumber(this.y.toFixed(2));
                    }
                }
            }
        },
        series: [{
            name: 'Total Profit',
            data: seriesData
        }],
        exporting: {
            enabled: true
        },
        lang: {
            downloadPNG: 'Download PNG image',
            downloadJPEG: 'Download JPEG image',
            downloadPDF: 'Download PDF document',
            downloadSVG: 'Download SVG vector image',
            downloadXLS: 'Download XLS data'
        }
    };
    return options;
}

const ChartProfit = ({data}) => {
    const[profitProperty,setProfitProperty] = useState([])
    const [topProfitablePropertiesData,
    {isLoading: isTopProfitablePropertiesLoading,
        isError: isTopProfitablePropertiesError}
     ] = useGetTopProfitablePropertiesMutation();
     let tokenData = localStorage?.getItem('Token');

    const profitableProperty = async()=>{
        const res = await topProfitablePropertiesData(data)
        setProfitProperty(res.data)
    }
    
    useEffect(()=>{
        if(tokenData !== undefined) {
        profitableProperty();
        }
    },[data.filter_type && data.filter_value,data.property_ids, tokenData]);
    
    if (isTopProfitablePropertiesLoading) return <><Spin /></>;
    if (isTopProfitablePropertiesError) return <p>Error loading data.</p>;

    const options = ProfitChart(data?.topProperties);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default ChartProfit;
