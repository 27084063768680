import { UserOutlined } from "@ant-design/icons/lib";
import React, { useEffect, useState } from "react";
import { Button, Form, Spin } from "antd";
import { RiUser2Line } from "react-icons/ri";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import DynamicForm from "../../component/Form";
import {
    useAddOwnerTypeMutation,
    useUserProfileMutation,
} from "../../slices/apiSlice";
import {
    useGetUserDetailQuery,
    useOwnerTypeQuery,
} from "../../slices/getSlice";
import { toast } from "react-toastify";
import DummyImage from "../../assets/images/profile-icon-9.png";
import ChangePassword from "../auth/changePassword";
import { useDispatch, useSelector } from "react-redux";
import Setting from "./Setting";
import CommonModal from "../../component/CommonModal";
import { LiaUserEditSolid } from "react-icons/lia";
import { userDetail, userStatus } from "../../slices/authSlice";
import { useNavigate } from "react-router-dom";
const Profile = () => {
    const dispatch = useDispatch();
    const {
        data: getUserDetail,
        isLoading: isDetailLoading,
        refetch,
        error
    } = useGetUserDetailQuery();
    // debugger
    const [ownerType, setOwnerType] = useState();
    const [form] = Form.useForm();
    const [modal] = Form.useForm();
    const [ModalOpen, setModalOpen] = useState(false);
    const [addOwnerType] = useAddOwnerTypeMutation();
    const [FormInitialValuesDiff, setFormInitialValuesDiff] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const currencyGlobal = useSelector(
        (state) => state?.persistedReducer?.currency
    );
    const currency = currencyGlobal ? currencyGlobal : "";
    const [data, setData] = useState();

    const modalField = [
        {
            component: "input",
            type: "text",
            label: `Owner Type`,
            name: "name",
            placeholder: `Enter Owner Type`,
            prefix: (
                <LiaUserEditSolid style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: `Please input your Owner Type!`,
                },
            ],
        },
    ];

    useEffect(() => {
        if (getUserDetail?.data) {
            setData(getUserDetail?.data);
    
            // refetch();
        }
    
    }, [getUserDetail]);

    const fields = [
        {
            component: "input",
            type: "text",
            label: "First Name",
            name: "first_name",
            placeholder: "Enter First Name",
            prefix: <UserOutlined style={{ color: "#F47939", fontSize: "25px" }} />,
            rules: [
                {
                    required: true,
                    message: "Please input first name",
                },
                {
                    pattern: /^[A-Za-z\s]+$/,
                    message: 'First name can only contain letters and spaces',
                },
                {
                    max: 25,
                    message: 'First name cannot exceed 25 characters',
                },
            ],
            colSpan: 12,
        },
        {
            component: "input",
            type: "text",
            label: "Last Name",
            name: "last_name",
            placeholder: "Enter Last Name",
            prefix: <UserOutlined style={{ color: "#F47939", fontSize: "25px" }} />,
            rules: [
                {
                    required: true,
                    message: "Please input last name",
                },
                {
                    pattern:  /^[A-Za-z\s]+$/,
                    message: 'Last name  must only contain letters',
                },
                {
                    max: 25,
                    message: 'Last name cannot exceed 25 characters',
                },
            ],
            colSpan: 12,
        },
        {
            component: "input",
            type: "text",
            label: "Company Name",
            name: "company_name",
            placeholder: "Enter company name",
            prefix: (
                <HiOutlineBuildingOffice
                    style={{ color: "#F47939", fontSize: "25px" }}
                />
            ),
            rules: [
                {
                    required: true,
                    message: "Please input your company name",
                },
                {
                    pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
                    message: '  Company name  must only contain letters',
                },
            ],
            colSpan: 12,
        },
        {
            component: "select",
            label: "Owner Type",
            name: "ownership_type_id",
            placeholder: "Please select",
            suffixIcon: (
                <RiUser2Line style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            options: ownerType,
            rules: [
                {
                    required: true,
                    message: "Please select owner type",
                },
            ],
            colSpan: 12,
        },
    ];

    const [userProfile] = useUserProfileMutation();
    const {
        data: ownertype,
        isLoading,
        isError,
        refetch: refetchOwnerType,
    } = useOwnerTypeQuery();

    useEffect(() => {
        if (ownertype && ownertype.data) {
            const transformedOptions = ownertype.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setOwnerType(transformedOptions);
        }
    }, [ownertype]);

    if (isLoading)
        return (
            <Spin
                size="large"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            />
        );
    if (isError) return <p>Error fetching product.</p>;
    const onFinish = async (values) => {
        setIsSaving(true);
        const value = {
            first_name: values?.first_name ?? data?.first_name,
            last_name: values?.last_name ?? data?.last_name,
            company_name: values?.company_name?? data?.company_name,
            ownership_type_id: values?.ownership_type_id ?? data?.ownership_type_id,
        }
        try {
            const response = await userProfile(value);
            if (response.data) {
                refetch();
                dispatch(userStatus(true));
                dispatch(
                    userDetail({
                        user: response?.data,
                    })
                );
                toast.success("Profile has been updated successfully");
               
            } else {
                toast.error(`${response?.error?.data?.message}`);
            }
        } catch (error) {
            toast.error("An error occurred");
        } finally {
            setIsSaving(false);
        }
    };
    const AddOwnerType = async (value) => {
       
        const res = await addOwnerType(value);
        if (res.data) {
            toast.success("Owner Type has been created successfully");
            refetchOwnerType();
            setModalOpen(false);
        } else {
            setModalOpen(true);
            toast.error(`${res?.error?.data?.message}`);
        }
    };
    return (
        <div className="mainWrapper">
            <div className="card accountCard">
                <div className="card-body p-4">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card profileCard">
                                <div className="card-body p-4">
                                    <div className="profileWrap">
                                        <div className="profileDetailsSection">
                                            <div className="profileImgSection">
                                                <img src={DummyImage} className="img-fluid" alt="img" />
                                            </div>
                                            <div className="userName">
                                                <h4>
                                                    {data?.first_name || data?.last_name
                                                        ? `${data?.first_name || data?.last_name}`
                                                        : "Your name"}
                                                </h4>
                                                <p>{data?.email}</p>
                                            </div>
                                        </div>
                                        <div className="profileInfoSection">
                                            <h4 className="infoTitle">Info</h4>
                                            <div className="inforWrap">
                                                <div className="infoItems">
                                                    <p className="infoLabel">Company</p>
                                                    <p className="infoData">
                                                        {data?.company_name
                                                            ? data?.company_name
                                                            : "Your company name"}
                                                    </p>
                                                </div>
                                                <div className="infoItems">
                                                    <p className="infoLabel">Owner Type</p>
                                                    <p className="infoData">
                                                        {data?.ownership_type?.name
                                                            ? data?.ownership_type?.name
                                                            : "Your ownership type"}
                                                    </p>
                                                </div>
                                                <div className="infoItems">
                                                    <p className="infoLabel">Currency</p>
                                                    <p className="infoData">{`${currency?currency?.currency_name:''}${" "}${currency?currency.currency_symbol:''}`}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="profileFormWrap">
                                <div className=" button-head">
                                    <p className="sectionTitle custom">Update Profile</p>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="btnFilled"
                                        onClick={() => {
                                            setModalOpen(true);
                                            setFormInitialValuesDiff({ name: "" });
                                        }}
                                    >
                                        Add Owner Type
                                    </Button>
                                </div>
                                {data &&
                                    <>
                                        <DynamicForm form={form} fields={fields} onFinish={onFinish} buttonName={'Save'} FormInitialValues={data ? data : null} ButtonDisable={isSaving} />
                                    </>
                                }
                             
                            </div>
                            <div className="profileFormWrap mt-4">
                                <p className="sectionTitle">Change Password</p>
                                <ChangePassword />
                            </div>
                            <div className="profileFormWrap mt-4">
                                <p className="sectionTitle">Setting</p>
                                <Setting />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CommonModal
                open={ModalOpen}
                close={() => setModalOpen(false)}
                form={modal}
                FormInitialValues={FormInitialValuesDiff}
                onFinish={AddOwnerType}
                fields={modalField}
                title={"Add Owner Type"}
            />
        </div>
    );
};

export default Profile;