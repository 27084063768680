import { Button, Form, Spin, Modal, Input } from "antd";
import Title from "../../component/PageTitle";
import { IoLocationOutline } from "react-icons/io5";
import { TbBuilding } from "react-icons/tb";
import { BsBuildings } from "react-icons/bs";
import { IoCodeSlashOutline } from "react-icons/io5";
import { LiaUserEditSolid } from "react-icons/lia";
import { PiHouseLine } from "react-icons/pi";
import DynamicForm from "../../component/Form";
import {
    useAddPropertyMutation,
    useAddPropertyTypeMutation,
    useEditPropertyMutation,
} from "../../slices/apiSlice";
import { toast } from "react-toastify";
import {
    useGetCityQuery,
    useGetCountriesQuery,
    useGetStateQuery,
    usePropetyTypeQuery
} from "../../slices/getSlice";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommonModal from "../../component/CommonModal";
const AddProperty = () => {
    const [form] = Form.useForm();
    const [modal] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [FormInitialValues, setFormInitialValues] = useState(location?.state);
    const [ModalOpen, setModalOpen] = useState(false);
    const [labelsModal, setLabelsModal] = useState(false);
    const [FormInitialValuesDiff, setFormInitialValuesDiff] = useState();
    const [selectedCountry, setSelectedCountry] = useState()
    const [selectedState, setSelectedState] = useState()

    const [countries, setCountries] = useState([])
    const [states, setStates] = useState()
    const [cities, setCities] = useState()
    const [propertyType, setPropertyType] = useState();
    const [addProperty, { isLoading: isaddLoading, isError: isaddError }] =
        useAddPropertyMutation();
    const [editProperty, { isLoading: iseditLoading, isError: iseditError }] =
        useEditPropertyMutation();
    const [addPropertyType] = useAddPropertyTypeMutation();
    const [isSaving, setIsSaving] = useState(false)
    const {
        data: propetytype,
        isLoading: ispropertyTypeLoading,
        isError: ispropertyTypeError,
        error: propertyTypeError,
        refetch: refetchPropertyType
    } = usePropetyTypeQuery();
    const { data: getCountries } = useGetCountriesQuery()
    const { data: getStates } = useGetStateQuery(selectedCountry ? selectedCountry : location?.state?.country)
    const { data: getCities } = useGetCityQuery(selectedState ? selectedState : location?.state?.state)

    useEffect(() => {
        if (propetytype && propetytype.data) {
            const transformedOptions = propetytype.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setPropertyType(transformedOptions);
        }
        if (getCountries && getCountries?.length > 0) {
            const transformedOptions = getCountries?.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setCountries(transformedOptions);
        }
        if (getStates?.length > 0) {
            const transformedOptions = getStates?.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setStates(transformedOptions);
        }
        if (getCities?.length > 0) {
            const transformedOptions = getCities?.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setCities(transformedOptions);
        }

    }, [propetytype, getCountries, getStates, getCities]);

    const fields = [
        {
            component: "input",
            type: "text",
            label: "Name of Property",
            name: "name_of_property",
            placeholder: "Enter name of property ",
            prefix: (
                <LiaUserEditSolid style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Please input your name of property",
                },
                {
                    pattern: /^(?!\s)[A-Za-z\s]+(?<!\s)$/,
                    message: ' name of property must only contain letters',
                },
            ],
            colSpan: 12,
        },
        {
            component: "select",
            label: "Property Type",
            name: "property_type_id",
            placeholder: "Please select property",
            suffixIcon: (
                <PiHouseLine style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Please select property type",
                },
            ],

            options: propertyType,
            colSpan: 12,
        },
        {
            component: "input",
            type: "text",

            label: "Address",
            name: "address",
            placeholder: "Enter address",
            prefix: (
                <IoLocationOutline style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Please input your address",
                },

            ],
            colSpan: 12,
        },
        {
            component: "select",
            type: "text",
            label: "Country",
            name: "country_id",
            placeholder: "Select your country  ",
            suffixIcon: (
                <LiaUserEditSolid style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Please select your country",
                },
            ],
            onChange: (value) => {
                setSelectedCountry(value)
                setFormInitialValues({
                    state_id: null,
                    city_id: null
                })
            },
            options: countries,
            colSpan: 12,
        },
        {
            component: "select",
            type: "text",
            label: "State",
            name: "state_id",
            placeholder: "Select your state",
            onChange: (value) => {
                setSelectedState(value);
            },
            suffixIcon: <TbBuilding style={{ color: "#F47939", fontSize: "25px" }} />,
            rules: [
                {
                    required: true,
                    message: "Please input your state",
                },
            ],
            options: states,
            colSpan: 12,
        },
        {
            component: "select",
            type: "text",
            label: "City",
            name: "city_id",
            placeholder: "Enter city",
            suffixIcon: <BsBuildings style={{ color: "#F47939", fontSize: "25px" }} />,
            rules: [
                {
                    required: true,
                    message: "Please input your city",
                },

            ],
            colSpan: 12,
            options: cities
        },
        {
            component: "input",
            type: "number",
            label: "Zip Code",
            name: "zip_code",
            placeholder: "Enter zip code",
            prefix: (
                <IoCodeSlashOutline style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            rules: [
                {
                    required: true,
                    message: "Please input your zip code",
                },
            ],
            colSpan: 12,
        },
    ];

    const modalField = [
        {
            component: "input",
            type: "text",
            label: `Property type`,
            name: "name",
            placeholder: `Enter property type`,
            prefix: (
                <LiaUserEditSolid style={{ color: "#F47939", fontSize: "25px" }} />
            ),
            // rules: [
            //   {
            //     required: true,
            //     message: `Please input your property type`,
            //   },
            //   {
            //     pattern: /^[A-Za-z]+$/,
            //     message: 'Property type name must only contain letters',
            //   },
            // ],
        },
    ];

    useEffect(() => {
        if (propetytype && propetytype.data) {
            const transformedOptions = propetytype.data.map((item) => ({
                label: item.name,
                value: item.id,
            }));
            setPropertyType(transformedOptions);
        }
    }, [propetytype]);

    if (ispropertyTypeLoading) return <Spin size="large" />;

    if (ispropertyTypeError) return <p>Error fetching product.</p>;

    const onFinish = async (value) => {

        setIsSaving(true)
        try {
            const id = location?.state?.id;

            const finalData = {
                address: value?.address ? value?.address : location?.state?.address,
                city_id: value?.city_id ? value?.city_id : location?.state?.city,
                name_of_property: value?.name_of_property
                    ? value?.name_of_property
                    : location?.state?.name_of_property,
                property_type_id: value?.property_type_id
                    ? value?.property_type_id
                    : location?.state?.property_typeId,
                state_id: value?.state_id ? value?.state_id : location?.state?.state,
                zip_code: value?.zip_code ? value?.zip_code : location?.state?.zip_code,
                country_id: value?.country_id ? value?.country_id : location?.state?.country,
            };

            if (id) {
                if (typeof finalData.property_type_id == "string") {
                    let prop_type = propetytype.data.find(
                        (item) => item.name === finalData.property_type_id
                    );
                    finalData.property_type_id = prop_type.id;
                }
                if (typeof finalData.country_id == "string") {
                    let country = getCountries?.find(
                        (item) => item.name === finalData.country_id
                    );
                    finalData.country_id = country?.id;
                }
                if (typeof finalData.state_id == "string") {
                    let state = getStates?.find(
                        (item) => item.name === finalData.state_id
                    );
                    finalData.state_id = state?.id;
                }
                if (typeof finalData.city_id == "string") {
                    let city = getCities?.find(
                        (item) => item.name === finalData.city_id
                    );
                    finalData.city_id = city?.id;
                }

                const response = await editProperty({ data: finalData, id });
                if (response.data) {
                    navigate("/properties");
                    toast.success("Property has been updated successfully");
                    form.resetFields();
                } else {
                    toast.error("Please fill correct information for edit property");
                }
            } else {

                try {
                    const response = await addProperty(value);
                    if (response.data) {
                        toast.success("Property has been created successfully");
                        navigate("/properties");
                        form.resetFields();
                    } else {
                        toast.error(`${response?.error?.data?.message}`);
                    }
                } catch (error) {

                    toast.error("Failed to add property");
                }
            }
        } catch {

        } finally {
            setIsSaving(false)
        }

    };

    const AddPropertyType = async (value) => {
        setIsSaving(true)
        try {
            const res = await addPropertyType(value);
            if (res?.data) {
                toast.success("Property Type has been created successfully");
                refetchPropertyType()
                setModalOpen(false);
            } else {
                toast.error(`${res?.error?.data?.message}`);
            }
        } catch {

        } finally {
            setIsSaving(false)
        }

    };

    const openLabelModal = () => {
        setLabelsModal(true);
    };

    const handleLabelModal = () => {
        setLabelsModal(false);
    };

    return (
        <div className="mainWrapper">
            <div className="card titleCard mb-4">
                <div className="card-body d-flex justify-content-between align-items-center">
                    {location?.state?.id ? (
                        <Title title="Edit Properties" />
                    ) : (
                        <Title title="Add Properties" />
                    )}
                    <div className="btnWrapper">
                    {location?.state?.id?'': <Button
                            type="primary"
                            className="btnOutlined"
                            onClick={() => {
                                openLabelModal()
                            }}
                        >
                            Change Labels
                        </Button>}
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btnFilled"
                            onClick={() => {
                                setModalOpen(true);
                                setFormInitialValuesDiff({ name: "" });
                            }}
                        >
                            Add Property Type
                        </Button>
                    </div>
                </div>
            </div>
            <div className="card tableCard">
                <div className="card-body">
                    <DynamicForm
                        form={form}
                        fields={fields}
                        onFinish={onFinish}
                        buttonName={location?.state?.id ? "Edit" : "Add"}
                        loading={isaddLoading}
                        FormInitialValues={FormInitialValues}
                        ButtonDisable={isSaving}
                    />
                </div>
            </div>
            <CommonModal
                open={ModalOpen}
                close={() => setModalOpen(false)}
                form={modal}
                FormInitialValues={FormInitialValuesDiff}
                onFinish={AddPropertyType}
                fields={modalField}
                title={"Add Property Type"}
                ButtonDisable={isSaving}
            />

            {/* Lables Modal */}
 <Modal
                title="Change Form Labels"
                open={labelsModal}
                onOk={handleLabelModal}
                onCancel={handleLabelModal}
                className="assign_model"
                width={720}
                footer={false}
            >
                <div className="modalBody">
                    <Form
                        name="labelForm"
                        className="modalForm"
                        layout="vertical"
                    >
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item
                                    name="property_name"
                                    label="Name of Property"
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="property_type"
                                    label="Property Type"
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="address"
                                    label="Address"
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="Country"
                                    label="country"
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="State"
                                    label="state"
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="City"
                                    label="city"
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item
                                    name="zip_code"
                                    label="zip_code"
                                >
                                    <Input
                                        type="text"
                                        placeholder="Enter label text here..."
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-12 text-center mt-4">
                                <Button type="primary" className="authBtn" >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>
    );
};

export default AddProperty;
