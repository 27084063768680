import { Button, Col, Form, Input, Row, Spin } from "antd";
import Title from "../../component/PageTitle";
import { IoLocationOutline } from "react-icons/io5";
import { TbBuilding } from "react-icons/tb";
import { BsBuildings } from "react-icons/bs";
import { IoCodeSlashOutline } from "react-icons/io5";
import { LiaUserEditSolid } from "react-icons/lia";
import { PiHouseLine } from "react-icons/pi";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FormItem from "antd/es/form/FormItem";
import { useSettingMutation } from "../../slices/apiSlice";
import { useGetUserDetailQuery } from "../../slices/getSlice";

const MenuLabel = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const location = useLocation();
    const [propertyType, setPropertyType] = useState();
    const [isSaving, setIsSaving] = useState(false)
    const [settingUpdate] = useSettingMutation();
    const {
        data: getUserDetail,
        refetch,
      } = useGetUserDetailQuery();
    const onFinish = async (value) => {
        let data ={
            is_menu_update:true,
            ...value
        }

        setIsSaving(true)
        try {
        const res = settingUpdate({ data, id: getUserDetail?.data?.setting?.id })
       refetch()
        }
        catch {

        } finally {
            setIsSaving(false)
        }

    };
    useEffect(()=>{
if(getUserDetail?.data?.setting?.custom_data)
{
form.setFieldsValue(getUserDetail?.data?.setting?.custom_data?.menu)
}
    },[getUserDetail])


    return (
        <div className="mainWrapper">
            <div className="card titleCard mb-4">
                <div className="card-body d-block">
                    <Title title="Labels" />
                    <p className="noteText">You can change the sidebar menu labels here.</p>
                </div>
            </div>
            <div className="card tableCard mb-4">
                <div className="card-header">
                    <h5 className="card-title mb-0 px-4">
                        Sidebar Labels
                    </h5>
                </div>
                <div className="card-body">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        className="labelsForms"
                    >
                        <div className="row">
                            <div className="col-md-4">
                                <FormItem
                                    name='dashboard'
                                    label='Dashboard'
                                >
                                    <Input
                                        className="form-control"
                                        type='text'
                                    />
                                </FormItem>
                            </div>
                            <div className="col-md-4">
                                <FormItem
                                    name='properties'
                                    label='Properties'
                                >
                                    <Input
                                        className="form-control"
                                        type='text'
                                    />
                                </FormItem>
                            </div>
                            <div className="col-md-4">
                                <FormItem
                                    name='groups'
                                    label='Groups'
                                >
                                    <Input
                                        className="form-control"
                                        type='text'
                                    />
                                </FormItem>
                            </div>
                            <div className="col-md-4">
                                <FormItem
                                    name='income'
                                    label='Income'
                                >
                                    <Input
                                        className="form-control"
                                        type='text'
                                    />
                                </FormItem>
                            </div>
                            <div className="col-md-4">
                                <FormItem
                                    name='sub_account'
                                    label='Sub Account'
                                >
                                    <Input
                                        className="form-control"
                                        type='text'
                                    />
                                </FormItem>
                            </div>
                            <div className="col-md-4">
                                <FormItem
                                    name='overall_performance'
                                    label='Performance'
                                >
                                    <Input
                                        className="form-control"
                                        type='text'
                                    />
                                </FormItem>
                            </div>
                            <div className="col-md-4">
                                <FormItem
                                    name='audit_log'
                                    label='Audit Log'
                                >
                                    <Input
                                        className="form-control"
                                        type='text'
                                    />
                                </FormItem>
                            </div>
                            <div className="col-md-4">
                                <FormItem
                                    name='transaction'
                                    label='Transaction'
                                >
                                    <Input
                                        className="form-control"
                                        type='text'
                                    />
                                </FormItem>
                            </div>
                            <div className="col-md-12 text-center mt-3">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={"authBtn"}
                                    disabled={isSaving}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default MenuLabel;
