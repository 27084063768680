import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import images from '../../constant/images';
import {
    Building,
    Formkit,
    Reports,
    Users,
    LogOut,
    Home
} from '../../constant/icons'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../slices/authSlice";
import { useDispatch, useSelector } from 'react-redux';
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { toast } from 'react-toastify';
import { useLogoutMutation, useSettingMutation } from "../../slices/apiSlice";
import { AiOutlineAudit } from "react-icons/ai";
import {GrTransaction } from "react-icons/gr";
import { IoSettingsOutline } from "react-icons/io5";
import { useGetUserDetailQuery } from "../../slices/getSlice";

const Sidebar = () => {
    const location = useLocation()
    const {
        data: getUserDetail,
        refetch,
      } = useGetUserDetailQuery();
    const [selectedKey, setSelectedKey] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Logo } = images;
    const status = useSelector((state) => state.persistedReducer.status);
    const userData = useSelector((state) => state.persistedReducer);
    const parent_id = userData?.user?.user?.parent_id;
    const [menuLabels, setMenuLabels] = useState({});
   const [logoutUser] = useLogoutMutation()
   const handleLogout = () => {
    dispatch(logout());
    localStorage.clear();
    navigate("/login");
    toast.success('Logout successfully');
};


    useEffect(() => {
        const path = location.pathname;
        let key = path;

        if (path === '/view_property' || path === '/add_property' || path === '/property_details') {
            key = '/properties';
        } else if (path === '/add_income') {
            key = '/income';
        }

        setSelectedKey(key);
       
        
 
    }, [location?.pathname,getUserDetail]);
    useEffect(() => {
        let mydata = getUserDetail?.data?.setting?.custom_data;
        if(typeof mydata != "undefined"){
     setMenuLabels(mydata?.menu) 
        }
        
    }, [getUserDetail]);

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
      };
    const items = [
        {
            key: '/dashboard',
            icon: <Home className="menu-icon" />,
            disabled: !status,
            path: '/dashboard',
            label: status ? <Link className="opacity" to={'/dashboard'}>{capitalizeFirstLetter(menuLabels?.dashboard) || 'Dashboard'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.dashboard) ||'Dashboard'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/properties',
            icon: <Building className="menu-icon" />,
            path: '/properties',
            disabled: !status,
            label: status ? <Link to={'/properties'}>{capitalizeFirstLetter(menuLabels?.properties) || 'Properties'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.properties) || 'Properties'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/manage_property',
            icon: <Formkit className="menu-icon" />,
            path: '/manage_property',
            hidden:parent_id?true:false,
            disabled: !status,
            label: status ? <Link to={'/manage_property'}>{capitalizeFirstLetter(menuLabels?.groups) || 'Groups'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.groups) || 'Groups'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/income',
            icon: <RiMoneyDollarCircleLine className="menu-icon" />,
            path: '/income',
            disabled: !status,
            label: status ? <Link to={'/income'}>{capitalizeFirstLetter(menuLabels?.income) || 'Income'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.income) || 'Income'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/sub_account',
            icon: <Users className="menu-icon" />,
            path: '/sub_account',
            hidden:parent_id?true:false,
            disabled: !status,
            label: status ? <Link to={'/sub_account'}>{capitalizeFirstLetter(menuLabels?.sub_account) || 'Sub Accounts'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.sub_account)||'Sub Accounts'}</span>,
            className: 'custom-menu-item',
        },
        {
            key: '/report',
            icon: <Reports className="menu-icon" />,
            path: '/report',
            hidden:parent_id?true:false,
            disabled: !status,
            label: status ? <Link to={'/report'}>{capitalizeFirstLetter(menuLabels?.overall_performance) ||'Overall Performance'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.overall_performance) ||'Overall Performance'}</span>,
            className: 'custom-menu-item reportBtn',
        },
        {
            key: '/audit-log',
            icon: <AiOutlineAudit className="menu-icon" />,
            path: '/audit-log',
            hidden:parent_id?true:false,
            disabled: !status,
            label: status ? <Link to={'/audit-log'}>{capitalizeFirstLetter(menuLabels?.audit_log) ||'Audit Log'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.audit_log) ||'Audit Log'}</span>,
            className: 'custom-menu-item reportBtn',
        },
        {
            key: '/all-transcation',
            icon:<GrTransaction className="menu-icon"/>,
            path: '/all-transcation',
            hidden:parent_id?true:false,
            disabled: !status,
            label: status ? <Link to={'/all-transcation'}>{capitalizeFirstLetter(menuLabels?.transaction) ||'Transcation'}</Link> : <span>{capitalizeFirstLetter(menuLabels?.transaction) ||'Transcation'}</span>,
            className: 'custom-menu-item reportBtn',
        },
        {
            key: '/setting',
            icon: <IoSettingsOutline className="menu-icon" />,
            label:status ? <Link to={'/setting'}>{'Setting'}</Link> : <span>{'Setting'}</span>,
            path:'/setting' ,
            hidden:parent_id?true:false,
            disabled: !status,
            className: 'custom-menu-item reportBtn',
        },
        {
            key:9,
            icon: <LogOut className="menu-icon" />,
            label: 'Logout',
            style: { marginTop: '44px' },
            className: 'custom-menu-item bottem-menu-item',
            onClick: handleLogout
        },
        
        
    ]

    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            style={{ background: '#3A54A5' }}
            width="20%"
            className="mobWidth"
        >
            <div className="logoContainer" >
                <Logo onClick={() => {
                    navigate('/dashboard')
                }} />
            </div>
            <Menu mode="inline" style={{ background: '#3A54A5', marginTop: '2rem' }} items={items} selectedKeys={[selectedKey]} >
            </Menu>
        </Sider>
    )
}
export default Sidebar;