import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Space,
  Upload,
} from "antd";
import { RiContactsLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";


const { Item: FormItem } = Form;

const DynamicForm = ({
  form,
  fields,
  onFinish,
  buttonName,
  onUtilityTypeChange,
  anotherButton,
  loading,
  dynamicfields,
  formType,
  ButtonDisable,
  FormInitialValues = {},
}) => {
  const [formValues, setFormValues] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [positions, setPositions] = useState({});
  const location = useLocation()
  const handleDropdownVisibleChange = (value) => {
    if(value){
      setDropdownVisible(true);
    }else{
      setDropdownVisible(false);
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
   

    if (changedValues?.provider_id) {
      if (onUtilityTypeChange) {
        onUtilityTypeChange(changedValues?.provider_id);
      }
    }
    if (allValues) {
      setFormValues(allValues);
    }
  };

  useEffect(() => {
    if (FormInitialValues?.company_id == null) {
      form.resetFields(["company_id"]);
    }
    if (FormInitialValues?.state_id == null) {
      form.resetFields(["state_id"]);
      form.resetFields(["city_id"]);
    }
  }, [FormInitialValues]);

  const handleFinish = (allValues) => {
    const staticValues = {};
    const dynamicValues = {};
    if (dynamicfields) {
      for (const field of allFields) {
        const value = formValues[field?.name];
        if (field?.static) {
          staticValues[field?.name] = value;
        } else {
          dynamicValues[field?.name] = value !== undefined ? value : field?.defaultValue;
        }
      }
      onFinish(staticValues, dynamicValues, formType);
    } else {
      setFormValues(allValues);
      onFinish(formValues, formType);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      toast.error('You can only upload JPG/PNG file!');
      return Upload.LIST_IGNORE;
    }

    const isGif = file.type === 'image/gif';
    if (isGif) {
      toast.error('GIF files are not allowed!');
      return Upload.LIST_IGNORE;
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error('Image must smaller than 2MB!');
      return Upload.LIST_IGNORE;
    }

    return isJpgOrPng && isLt2M;
  };

  const handleDragStop = (e, data, fieldName) => {
    setPositions((prevPositions) => ({
      ...prevPositions,
      [fieldName]: { x: data.x, y: data.y },
    }));
  };

 

  const allFields = dynamicfields ? [...fields, ...dynamicfields] : [...fields];
 
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      onValuesChange={handleValuesChange}
      initialValues={FormInitialValues ? FormInitialValues : formValues}
    >
      <Row gutter={16}>
        {allFields.map((field, index) => {
          const isHidden = field?.hidden
            ? typeof field?.hidden === "function"
              ? field?.hidden()
              : field?.hidden
            : "";
          return (
            !isHidden && (
              <Col key={field?.name} md={field?.colSpan || 24} offset={field?.offset || 0}>
                {(field?.component === "input" || !field?.component) &&
                  (field?.type === "file" ? (
                    <>
                   
                      <FormItem
                        name={field?.name}
                        label={field?.label}
                        valuePropName="file"
                        getValueFromEvent={(e) => e && e.fileList && e.fileList[0]}
                        key={field?.name}
                      >
                        <Upload
                          listType="picture"
                          className="uploadBtn"
                          maxCount={1}
                          beforeUpload={beforeUpload}
                        >
                          <Button icon={<UploadOutlined />}>
                            {field?.placeholder}
                          </Button>
                        </Upload>
                      </FormItem>
                  
                    </>
                  ) : (
                    // <Draggable disabled={location.pathname === '/property_details'?false: true}
                    // position={positions[field.name] || { x: 0, y: 0 }}
                    // onStop={(e, data) => handleDragStop(e, data, field.name)}>
                       <FormItem
                      label={field?.label}
                      name={field?.name}
                      rules={field?.rules}
                      dependencies={field?.dependencies ? [field?.dependencies] : undefined}
                    >
                      {field?.type === "password" ? (
                        <Input.Password
                          placeholder={field?.placeholder}
                          defaultValue={field?.defaultValue}
                          className="form-control"
                          prefix={field?.prefix}
                          disabled={field?.disabled || ""}
                          type={field?.type}
                        />
                      ) : (
                        <Input
                          placeholder={field?.placeholder}
                          className="form-control"
                          defaultValue={field?.defaultValue}
                          disabled={field?.disabled || ""}
                          prefix={
                            field?.prefix ? (
                              field?.prefix
                            ) : (
                              <RiContactsLine style={{ color: "#F47939", fontSize: "25px" }} />
                            )
                          }
                          type={field?.type}
                        />
                      )}
                    </FormItem>
                    // </Draggable>
                   
                  ))}
                {field?.component === "select" && (
                    // <Draggable disabled={location.pathname === '/property_details'?false: true}>
                  <FormItem label={field?.label} name={field?.name} rules={field?.rules}>
                   <Select
                      id={index}                  
                      dropdownRender={(originNode) => (
                        <div
                          style={{
                            padding:'0px',
                            display: field?.label === 'Expense Type' || field?.label === "Frequency" || field?.label === "Type of Utility" || field?.label === "Utility company" ?'block':dropdownVisible ? 'block' : 'none', // Initially hidden
                          }}
                        >
                          {originNode}
                        </div>)}
                      className="formControl select-custom "
                      disabled={field.disabled || ""}
                      showSearch
                      onSelect={()=>setDropdownVisible(false)}
                      onSearch={handleDropdownVisibleChange}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      mode={field.mode || ""}
                      optionLabelProp="label"
                      placeholder={field?.placeholder}
                      options={field?.options}
                      suffixIcon={field?.suffixIcon}
                      onChange={(value) => {
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          [field?.name]: value,
                        }));
                        if (field?.onChange) {
                          field?.onChange(value);
                         
                        }
                        if (
                          field?.name === "provider_id" &&
                          onUtilityTypeChange
                        ) {
                          onUtilityTypeChange(value);
                        }
                       
                      }}
                      optionRender={(option) => (
                        <Space>
                          <span role="img" aria-label={option.data.value}>
                            {option.data.label}
                          </span>
                        </Space>
                      )}
                    />
                  </FormItem>
                  // </Draggable>
                )}
                {field?.component === "checkbox" && (
                 
                    <Row justify="space-between">
                      <Col span={12}>
                      <FormItem name={field?.name} valuePropName="checked">
                        <Checkbox className="rememberMe"
                         onChange={(value) => {
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            [field.name]: value,
                          }));
                          if (field?.onChange) {
                            field?.onChange(value);
                          }
                        }
                        }
                        >{field?.label}</Checkbox>
                        </FormItem>
                      </Col>
                      {buttonName === "Sign In" && (
                        <Col span={12} className="forgotpassword">
                          <Link
                            to="/forgot-password"
                            style={{ lineHeight: "32px" }}
                            className="forgotLink"
                          >
                            Forgot Password?
                          </Link>
                        </Col>
                      )}
                    </Row>
                  
                )}
              </Col>
            )
          );
        })}
      </Row>
      <Row className="formButtons">
        <Col md={24} className="text-center mt-3">
          {anotherButton && <>{anotherButton}</>}
          <Button
            type="primary"
            htmlType="submit"
            className={anotherButton ? "btnFilled ms-3 w-150" : "authBtn"}
            disabled={loading ? loading : ButtonDisable}
          >
            {loading ? "Loading..." : buttonName}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default DynamicForm;
